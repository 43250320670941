import ReactGA from 'react-ga4';

export const initGA = () => {
    if (process.env.REACT_APP_MEASUREMENT_ID) {
        ReactGA.initialize(process.env.REACT_APP_MEASUREMENT_ID); // Replace with your Measurement ID
    } else return;
};

export const trackPageView = (url) => {
    ReactGA.send({ hitType: 'pageview', page: url });
};

import {
    setAppPrepared,
    setUserInfo,
    setBoosts,
    setFriends,
    setTotalFriends,
    setReferralTokens,
    setTasks,
    setAchieves,
    setTonAmount,
    setError,
    setProducts,
    setGames,
    setCurrentFriendsPage,
    setTotalFriendsPages,
    setWithdraws,
    setCurrentWithdrawsPage,
    setWithdrawsLinkNext,
    setFriendsLinkNext,
    setDailyRewards,
    setSettings,
    setTotalTons,
    setDailyRewardStreak,
} from '../store/commonReducer';
import { request } from './index';
import axios from 'axios';
import log from 'loglevel';
import toast from 'react-hot-toast';
import { displayNotification } from './common';

const botUrl = process.env.REACT_APP_BOT;
const coinname = process.env.REACT_APP_COIN_NAME;

const sendUserInfo = async (initData, refferal, dispatch) => {
    try {
        if (initData) {
            const url = refferal ? `/init?refers_to=${refferal}` : '/init';
            const response = await request.post(
                url,
                { initData: initData },
                {},
                (callback) => {
                    if (callback) {
                        dispatch(setUserInfo(callback));
                    } else {
                        log.error('No callback from server');
                    }
                },
                (error) => {
                    if (error.response && error.response.status === 500) {
                        dispatch(
                            setError({
                                description:
                                    'Our app is currently undergoing technical maintenance',
                                code: 'Please be patient',
                            })
                        );
                    } else if (
                        error.response &&
                        error.response.status === 429
                    ) {
                        dispatch(
                            setError({
                                description:
                                    'Our app is currently undergoing technical maintenance',
                                code: 'Please be patient',
                            })
                        );
                    }
                }
            );
            if (response && response.status === 500) {
                return response;
            }
        } else {
            log.error('Telegram data is not valid:');
            dispatch(setAppPrepared(true));
        }
    } catch (err) {
        log.error('Error: ', err);
    }
};

const sendUserClaim = async (initData, dispatch) => {
    try {
        if (initData) {
            await request.post(
                '/claim',
                { initData: initData },
                {},
                (callback) => {
                    if (callback) {
                        dispatch(setUserInfo(callback));
                        toast.success(`Yay, new ${coinname} received!`);
                    } else {
                        log.warn('Failed to fetch data');
                    }
                },
                (error) => {
                    log.error('Error: ', error);
                }
            );
        } else {
            return;
        }
    } catch (err) {
        log.error('Error: ', err);
    }
};

const getUserBoosts = async (initData, dispatch) => {
    try {
        if (initData) {
            await request.get(
                '/boosts',
                { initData: initData },
                (callback) => {
                    if (callback) {
                        dispatch(setBoosts(callback));
                        if (Array.isArray(callback)) {
                            let updatedBoosts = callback.map((boost) => {
                                return {
                                    ...boost,
                                    loading: false,
                                };
                            });
                            dispatch(setBoosts(updatedBoosts));
                        }
                    } else {
                        log.warn('Failed to fetch data');
                    }
                },
                (error) => {
                    log.error('Error: ', error);
                }
            );
        } else {
            return;
        }
    } catch (err) {
        log.error('Error: ', err);
    }
};

const purchaseUserBoost = async (id, initData, userInfo, dispatch) => {
    try {
        if (initData && id) {
            const result = await request.post(
                `/boosts/${id}`,
                { initData: initData },
                {},
                (callback) => {
                    if (callback) {
                        sendUserInfo(initData, null, dispatch).then(() => {
                            if (Array.isArray(callback.data)) {
                                let updatedBoosts = callback.data.map(
                                    (boost) => {
                                        return {
                                            ...boost,
                                            loading: false,
                                            disabled: false,
                                        };
                                    }
                                );
                                dispatch(setBoosts(updatedBoosts));
                            }
                        });
                        toast.success(`Upgraded your boost!`);
                    } else {
                        log.warn('Failed to fetch data');
                    }
                },
                (error) => {
                    log.error('Error: ', error);
                    if (error.response && error.response.status === 404) {
                        toast.error(`Some problems during upgrading 404!`);
                    } else if (
                        error.response &&
                        error.response.status === 500
                    ) {
                        toast.error(`Some problems during upgrading 500!`);
                    } else {
                        toast.error(`Some problems during upgrading!`);
                    }
                }
            );
            return result;
        } else {
            return;
        }
    } catch (err) {
        log.error('Error: ', err);
    }
};

const sendCompleteTask = async (initData, userInfo, dispatch, id, hashKey) => {
    try {
        if (initData) {
            const formData = new FormData();

            if (hashKey) {
                formData.append('hash', hashKey);

                const result = await request.post(
                    `/missions/${id}`,
                    { initData: initData },
                    formData,
                    (callback) => {
                        if (callback) {
                            const balance = callback.balance;
                            const newUserInfo = {
                                ...userInfo,
                                balance: balance,
                            };
                            dispatch(setUserInfo(newUserInfo));
                            dispatch(setTasks(callback.data));
                        } else {
                            console.warn('Error trying complete transact task');
                        }
                    },
                    (error) => {
                        console.error('Error: ', error);
                    }
                );
                return result;
            } else {
                const result = await request.post(
                    `/missions/${id}`,
                    { initData: initData },
                    {},
                    (callback) => {
                        if (callback) {
                            const balance = callback.balance;
                            const newUserInfo = {
                                ...userInfo,
                                balance: balance,
                            };
                            dispatch(setUserInfo(newUserInfo));
                            dispatch(setTasks(callback.data));
                        } else {
                            log.warn('Failed to fetch data');
                        }
                    },
                    (error) => {
                        log.error('Error: ', error);
                    }
                );
                return result;
            }
        } else {
            return;
        }
    } catch (err) {
        log.error('Error: ', err);
    }
};

const sendCompleteBoost = async (initData, userInfo, dispatch, id, hashKey) => {
    try {
        if (initData) {
            const formData = new FormData();

            if (hashKey) {
                formData.append('hash', hashKey);
                const result = await request.post(
                    `/products/${id}`,
                    { initData: initData },
                    formData,
                    (callback) => {
                        if (callback) {
                            dispatch(setProducts(callback));
                            sendUserInfo(initData, null, dispatch);
                        } else {
                            console.warn('Error trying complete transact');
                        }
                    },
                    (error) => {
                        console.error('Error: ', error);
                    }
                );

                return result;
            } else {
                const result = await request.post(
                    `/products/${id}`,
                    { initData: initData },
                    {},
                    (callback) => {
                        if (callback) {
                            dispatch(setProducts(callback));
                            sendUserInfo(initData, null, dispatch);
                        } else {
                            log.warn('Failed to fetch data');
                        }
                    },
                    (error) => {
                        log.error('Error: ', error);
                    }
                );

                return result;
            }
        } else {
            return;
        }
    } catch (err) {
        log.error('Error: ', err);
    }
};

const sendCompleteAchievement = async (initData, userInfo, dispatch, id) => {
    try {
        if (initData) {
            const result = await request.post(
                `/achieves/${id}`,
                { initData: initData },
                {},
                (callback) => {
                    if (callback) {
                        const balance = callback.balance;
                        const newUserInfo = {
                            ...userInfo,
                            balance: balance,
                        };
                        dispatch(setUserInfo(newUserInfo));
                        dispatch(setAchieves(callback.data));
                    } else {
                        log.warn('Failed to fetch data');
                    }
                },
                (error) => {
                    log.error('Error: ', error);
                }
            );
            return result;
        } else {
            return;
        }
    } catch (err) {
        log.error('Error: ', err);
    }
};

const fetchReferralData = async (page, initData, dispatch, append, friends) => {
    try {
        if (initData) {
            await request.get(
                `/referral?page=${page}&perPage=10`,
                { initData: initData },
                (callback) => {
                    if (callback) {
                        if (append) {
                            const newFriendsArray = [
                                ...friends,
                                ...callback.friends,
                            ];
                            dispatch(
                                setCurrentFriendsPage(
                                    callback.meta.current_page
                                )
                            );
                            dispatch(setFriendsLinkNext(callback.links.next));
                            dispatch(setFriends(newFriendsArray));
                        } else {
                            dispatch(setFriends(callback.friends));
                            dispatch(setTotalFriends(callback.meta.total));
                            dispatch(
                                setTotalFriendsPages(callback.meta.last_page)
                            );
                            dispatch(
                                setCurrentFriendsPage(
                                    callback.meta.current_page
                                )
                            );
                            dispatch(
                                setReferralTokens(callback.referral_tokens)
                            );
                            dispatch(setFriendsLinkNext(callback.links.next));
                        }
                    } else {
                        log.warn('Failed to fetch data');
                    }
                },
                (error) => {
                    log.error('Error: ', error);
                }
            );
        } else {
            return;
        }
    } catch (err) {
        log.error('Error: ', err);
    }
};

const fetchWithdrawData = async (
    page,
    initData,
    dispatch,
    append,
    withdraws
) => {
    try {
        if (initData) {
            await request.get(
                `/withdraw?page=${page}&perPage=10`,
                { initData: initData },
                (callback) => {
                    if (callback) {
                        if (append) {
                            const newWithdrawsArray = [
                                ...withdraws,
                                ...callback.data,
                            ];
                            dispatch(
                                setCurrentWithdrawsPage(
                                    callback.meta.current_page
                                )
                            );
                            dispatch(setWithdrawsLinkNext(callback.links.next));
                            dispatch(setWithdraws(newWithdrawsArray));
                            dispatch(setTotalTons(callback.total));
                        } else {
                            dispatch(setWithdraws(callback.data));
                            dispatch(
                                setCurrentWithdrawsPage(
                                    callback.meta.current_page
                                )
                            );
                            dispatch(setWithdrawsLinkNext(callback.links.next));
                            dispatch(setTotalTons(callback.total));
                        }
                    } else {
                        log.warn('Failed to fetch data');
                    }
                },
                (error) => {
                    log.error('Error: ', error);
                }
            );
        } else {
            return;
        }
    } catch (err) {
        log.error('Error: ', err);
    }
};

const fetchGames = async (initData, dispatch) => {
    try {
        if (initData) {
            await request.get(
                `/games`,
                { initData: initData },
                (callback) => {
                    if (callback) {
                        dispatch(setGames(callback));
                    } else {
                        log.warn('Failed to fetch data');
                    }
                },
                (error) => {
                    log.error('Error: ', error);
                }
            );
        } else {
            return;
        }
    } catch (err) {
        log.error('Error: ', err);
    }
};

const fetchNotifications = async (initData, dispatch) => {
    try {
        if (initData) {
            await request.get(
                `/notifications`,
                { initData: initData },
                (callback) => {
                    if (callback) {
                        if (callback.length > 0) {
                            toast.dismiss();

                            callback.forEach((notification, index) => {
                                setTimeout(() => {
                                    displayNotification(
                                        notification,
                                        initData,
                                        dispatch
                                    );
                                }, index * 1000);
                            });
                        } else {
                            return;
                        }
                    } else {
                        log.warn(
                            'Failed to fetch data or response is not an array'
                        );
                        return;
                    }
                },
                (error) => {
                    log.error('Error: ', error);
                }
            );
        } else {
            return;
        }
    } catch (err) {
        log.error('Error fetching notifications:', err);
    }
};

const getUserTasks = async (initData, dispatch) => {
    try {
        if (initData) {
            await request.get(
                '/missions',
                { initData: initData },
                (callback) => {
                    if (callback) {
                        dispatch(setTasks(callback));
                    } else {
                        log.warn('Failed to fetch data');
                    }
                },
                (error) => {
                    log.error('Error: ', error);
                }
            );
        } else {
            return;
        }
    } catch (err) {
        log.error('Error: ', err);
    }
};

const getProducts = async (initData, dispatch) => {
    try {
        if (initData) {
            await request.get(
                '/products',
                { initData: initData },
                (callback) => {
                    if (callback) {
                        dispatch(setProducts(callback));
                    } else {
                        log.warn('Failed to fetch data');
                    }
                },
                (error) => {
                    log.error('Error: ', error);
                }
            );
        } else {
            return;
        }
    } catch (err) {
        log.error('Error: ', err);
    }
};

const getUserAchievements = async (initData, dispatch) => {
    try {
        if (initData) {
            await request.get(
                '/achieves',
                { initData: initData },
                (callback) => {
                    if (callback) {
                        dispatch(setAchieves(callback));
                    } else {
                        log.warn('Failed to fetch data');
                    }
                },
                (error) => {
                    log.error('Error: ', error);
                }
            );
        } else {
            return;
        }
    } catch (err) {
        log.error('Error: ', err);
    }
};

const sendStorageRemind = async (chatId, initData) => {
    try {
        if (initData) {
            const response = await request.post(
                `/referral/remind/${chatId}`,
                { initData: initData },
                (callback) => {
                    if (callback) {
                        toast.success('Nice, your friend reminded!');
                    } else {
                        log.warn('Failed to fetch data');
                    }
                },
                (error) => {
                    log.error('Error: ', error);
                }
            );

            if (response.data.ok) {
                log.info('Message sent successfully');
                toast.success('Remind was sended!');
            } else {
                log.error('Error: ', 'message not sended');
            }
        } else {
            return;
        }
    } catch (err) {
        log.error('Error: ', err);
    }
};

const getTonInfo = async (initData, dispatch) => {
    try {
        if (initData) {
            const response = await axios.get(
                'https://tonapi.io/v2/rates?tokens=ton&currencies=usd'
            );

            if (response) {
                const rate = response.data.rates.TON.prices.USD;
                const rateFixed = Number(rate).toFixed(6);
                dispatch(setTonAmount(rateFixed));
                return rateFixed;
            } else {
                log.error('Some error occured during ton info receiving');
                return;
            }
        } else {
            return;
        }
    } catch (err) {
        log.error('Error: ', err);
        return;
    }
};

const buyCheckinWithTgStars = async (id, currentTaskId, init_data, amount) => {
    try {
        const data = {
            amount: amount,
            action: 'buy_checkin',
            user_id: id,
            mission_id: currentTaskId,
            init_data: init_data,
        };

        const response = await axios.post(`${botUrl}/buy_stars`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response) {
            return response.data;
        } else {
            toast('Cant open stars payment');
            return;
        }
    } catch (err) {
        log.error('Error: ', err);
        return;
    }
};

const buyProductWithTgStars = async (id, currentBoostId, init_data, amount) => {
    try {
        const data = {
            amount: amount,
            action: 'buy_product',
            user_id: id,
            boost_id: currentBoostId,
            init_data: init_data,
        };

        const response = await axios.post(`${botUrl}/buy_stars`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response) {
            return response.data;
        } else {
            toast('Cant open stars payment');
            return;
        }
    } catch (err) {
        log.error('Error: ', err);
        return;
    }
};

const buyTicketWithTgStars = async (id, currentBoostId, init_data, amount) => {
    try {
        const data = {
            amount: amount,
            action: 'buy_product',
            user_id: id,
            boost_id: currentBoostId,
            init_data: init_data,
        };

        const response = await axios.post(`${botUrl}/buy_stars`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response) {
            return response.data;
        } else {
            toast('Cant open stars payment');
            return;
        }
    } catch (err) {
        log.error('Error: ', err);
        return;
    }
};

const sendPurchaseTicket = async (
    initData,
    userInfo,
    dispatch,
    id,
    hashKey
) => {
    try {
        /*if (initData) {
            const formData = new FormData();

            if (hashKey) {
                formData.append('hash', hashKey);
                const result = await request.post(
                    `/products/${id}`,
                    { initData: initData },
                    formData,
                    (callback) => {
                        if (callback) {
                            dispatch(setProducts(callback));
                            sendUserInfo(initData, null, dispatch);
                        } else {
                            console.warn('Error trying complete transact');
                        }
                    },
                    (error) => {
                        console.error('Error: ', error);
                    }
                );

                return result;
            } else {
                const result = await request.post(
                    `/products/${id}`,
                    { initData: initData },
                    {},
                    (callback) => {
                        if (callback) {
                            dispatch(setProducts(callback));
                            sendUserInfo(initData, null, dispatch);
                        } else {
                            log.warn('Failed to fetch data');
                        }
                    },
                    (error) => {
                        log.error('Error: ', error);
                    }
                );

                return result;
            }
        } else {
            return;
        }*/
    } catch (err) {
        log.error('Error: ', err);
    }
};

const claimDailyReward = async (initData, dispatch) => {
    try {
        if (initData) {
            await request.post(
                '/daily-reward',
                { initData: initData },
                {},
                (callback) => {
                    if (callback) {
                        dispatch(setUserInfo(callback));
                        toast.success(
                            `Yay, new ${coinname} and Tickets received!`
                        );
                        setTimeout(() => {
                            getDailyRewards(initData, dispatch);
                        }, 1000);
                    } else {
                        log.warn('Failed to fetch data');
                    }
                },
                (error) => {
                    log.error('Error: ', error);
                }
            );
        } else {
            return;
        }
    } catch (err) {
        log.error('Error: ', err);
    }
};

const getDailyRewards = async (initData, dispatch) => {
    try {
        if (initData) {
            await request.get(
                '/daily-reward',
                { initData: initData },
                (callback) => {
                    if (callback) {
                        const sortedRewards = callback.data.sort(
                            (a, b) => a.day_in_streak - b.day_in_streak
                        );

                        const seenStreaks = new Set();
                        const filteredRewards = sortedRewards.filter((item) => {
                            if (!seenStreaks.has(item.day_in_streak)) {
                                seenStreaks.add(item.day_in_streak);
                                return true;
                            }
                            return false;
                        });

                        const reindexedRewards = filteredRewards.map(
                            (item, index) => ({
                                ...item,
                                day_in_streak: index + 1,
                            })
                        );
                        dispatch(setDailyRewards(reindexedRewards));
                        dispatch(setDailyRewardStreak(callback.daily_streak));
                    } else {
                        log.warn('Failed to fetch data');
                    }
                },
                (error) => {
                    log.error('Error: ', error);
                }
            );
        } else {
            return;
        }
    } catch (err) {
        log.error('Error: ', err);
    }
};

const sendUserWithdrawalRequest = async (tonAddress, initData, dispatch) => {
    try {
        if (initData) {
            const formData = new FormData();

            if (tonAddress) {
                formData.append('wallet', tonAddress);

                await request.post(
                    '/withdraw',
                    { initData: initData },
                    formData,
                    (callback) => {
                        if (callback) {
                            dispatch(setWithdraws(callback.data));
                            dispatch(
                                setCurrentWithdrawsPage(
                                    callback.meta.current_page
                                )
                            );
                            dispatch(setWithdrawsLinkNext(callback.links.next));
                            toast.success(
                                `Request for tons is submited,\nit can take a few days!`,
                                { duration: 6000 }
                            );
                        } else {
                            log.warn('Failed to make request');
                        }
                    },
                    (error) => {
                        log.error('Error: ', error);
                    }
                );
            }
        } else {
            return;
        }
    } catch (err) {
        log.error('Error: ', err);
    }
};

const fetchSettings = async (initData, dispatch) => {
    try {
        if (initData) {
            await request.get(
                '/settings',
                { initData: initData },
                (callback) => {
                    if (callback) {
                        dispatch(setSettings(callback));
                    } else {
                        log.warn('Failed to make request');
                    }
                },
                (error) => {
                    log.error('Error: ', error);
                }
            );
        } else {
            return;
        }
    } catch (err) {
        log.error('Error: ', err);
    }
};

export {
    sendUserInfo,
    sendUserClaim,
    buyCheckinWithTgStars,
    buyProductWithTgStars,
    buyTicketWithTgStars,
    sendCompleteTask,
    sendCompleteAchievement,
    getUserBoosts,
    getUserTasks,
    getUserAchievements,
    getTonInfo,
    getProducts,
    getDailyRewards,
    purchaseUserBoost,
    fetchReferralData,
    fetchWithdrawData,
    fetchNotifications,
    sendStorageRemind,
    sendCompleteBoost,
    sendPurchaseTicket,
    claimDailyReward,
    fetchGames,
    fetchSettings,
    sendUserWithdrawalRequest,
};

import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    setRemindedFriends,
    setAppPrepared,
    setOpenResumeCollectorModal,
} from './store/commonReducer';
import { BrowserRouter } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import {
    sendUserInfo,
    getUserBoosts,
    getUserAchievements,
    getUserTasks,
    fetchReferralData,
    fetchGames,
    getProducts,
    getDailyRewards,
    getTonInfo,
    fetchWithdrawData,
    fetchSettings,
} from './tools/requests';
import RouterSwitch from './RouterSwitch';
import Roadmap from './pages/Roadmap/Roadmap';
import startNotificationLoop from './hooks/useNotification';
import toast from 'react-hot-toast';
import Background from './layouts/Background/Background';
import Tutorial from './pages/Tutorial/Tutorial';
import Error from './pages/Error/Error';
import Popup from './components/Popup/Popup';
import AutoCollectoTimeoutModal from './modals/AutoCollectoTimeoutModal/AutoCollectoTimeoutModal';
import Modal from './components/Modal/Modal';
import DailyClaimModal from './modals/DailyClaimModal/DailyClaimModal';
import './style/main.scss';

function App() {
    const dispatch = useDispatch();
 
    const tg = useMemo(() => window.Telegram.WebApp, []);
    const initData = tg.initData;

    const isAppPrepared = useSelector((state) => state.common.appPrepared);
    const userInfo = useSelector((state) => state.common.userInfo);
    const friends = useSelector((state) => state.common.friends);
    const errorStatus = useSelector((state) => state.common.error);
    const dailyStreak = useSelector((state) => state.common.dailyStreak);
    const openResumeCollectorModal = useSelector(
        (state) => state.common.openResumeCollectorModal
    );

    const [isTimeoutCollectorOpen, setIsTimeoutCollectorOpen] = useState(false);
    const [error, setError] = useState(null);
    const [showRoadmap, setShowRoadmap] = useState(true);
    const [showTutorial, setShowTutorial] = useState(false);
    const [openDailyClaimModal, setOpenDailyClaimModal] = useState(false);

    useEffect(() => {
        if (errorStatus) {
            setError(errorStatus);
        }
    }, [errorStatus]);

    useEffect(() => {
        if (openResumeCollectorModal === true) {
            setIsTimeoutCollectorOpen(true);
        } else {
            setIsTimeoutCollectorOpen(false);
        }
    }, [openResumeCollectorModal]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const tgWebAppStartParam = urlParams.get('tgWebAppStartParam');

        const referralCode = tgWebAppStartParam;

        if (initData) {
            sendUserInfo(initData, referralCode, dispatch)
                .then(() => {
                    const promises = [
                        fetchSettings(initData, dispatch),
                        getUserBoosts(initData, dispatch),
                        getUserTasks(initData, dispatch),
                        getUserAchievements(initData, dispatch),
                        getProducts(initData, dispatch),
                        getDailyRewards(initData, dispatch),
                        fetchGames(initData, dispatch),
                        getTonInfo(initData, dispatch),
                        fetchReferralData(
                            1,
                            initData,
                            dispatch,
                            false,
                            friends
                        ),
                        fetchWithdrawData(1, initData, dispatch, false, []),
                    ];
                    return Promise.all(promises);
                })
                .then(() => {
                    const preloadRoutes = [
                        'Main',
                        'Missions',
                        'Boost',
                        'Friends',
                        'Apps',
                    ].map((route) => import(`./pages/${route}/${route}`));

                    return Promise.all(preloadRoutes);
                })
                .then(() => {
                    // Load reminded friends from local storage
                    const storedRemindedFriends =
                        JSON.parse(localStorage.getItem('remindedFriends')) ||
                        [];
                    const now = Date.now();
                    const validRemindedFriends = storedRemindedFriends.filter(
                        (friend) => friend.expiredAt > now
                    );

                    validRemindedFriends.forEach((friend) => {
                        dispatch(setRemindedFriends(friend));
                    });

                    // Update local storage with valid reminded friends
                    localStorage.setItem(
                        'remindedFriends',
                        JSON.stringify(validRemindedFriends)
                    );

                    setTimeout(() => {
                        startNotificationLoop(initData, dispatch);
                    }, 5000);

                    dispatch(setAppPrepared(true));
                })
                .catch((err) => {
                    console.error('Some problem during initialization', err);
                });
        } else {
            setError(
                <>
                    <h3>It seems like you're trying to access</h3>
                    <h3>an app not via Telegram</h3>
                    <h3>The app will not go further!</h3>
                </>
            );
        }
    }, [initData, dispatch]);

    useEffect(() => {
        const handleInvoiceClosed = (object) => {
            if (object.status === 'paid') {
                toast.loading(
                    'Transaction is processed, it may take up to 2 minutes.'
                );
            } else if (object.status === 'failed') {
                toast.error('Telegram stars transaction failed');
            }
        };

        tg.onEvent('invoiceClosed', handleInvoiceClosed);

        return () => {
            tg.offEvent('invoiceClosed', handleInvoiceClosed);
        };
    }, [tg]);

    useEffect(() => {
        const roadmapTimer = setTimeout(() => {
            setShowRoadmap(false);

            const tutorialShown = localStorage.getItem('tutorialShown');
            if (!tutorialShown) {
                setShowTutorial(true);
            }
        }, 3000); // Show roadmap for 3 seconds

        return () => clearTimeout(roadmapTimer);
    }, []);

    useEffect(() => {
        if (!showTutorial && !showRoadmap && isAppPrepared && !error) {
            if (userInfo?.daily_reward) {
                const timer = setTimeout(() => {
                    setOpenDailyClaimModal(true);
                }, 3000);

                return () => clearTimeout(timer);
            }
        }
    }, [
        userInfo?.daily_reward,
        error,
        showTutorial,
        showRoadmap,
        isAppPrepared,
    ]);

    const handleCloseTutorial = () => {
        localStorage.setItem('tutorialShown', 'true');
        setShowTutorial(false);
    };

    if (error) {
        return <Error description={error} code={401} />;
    }

    if (errorStatus) {
        return (
            <Error
                description={errorStatus.description}
                code={errorStatus.code}
            />
        );
    }

    if (!isAppPrepared && !error) {
        return <Roadmap />;
    }

    if (isAppPrepared && !userInfo) {
        return <Error />;
    }

    if (showRoadmap) {
        return <Roadmap />;
    }

    if (showTutorial) {
        return <Tutorial onClose={handleCloseTutorial} />;
    }

    return (
        <BrowserRouter>
            <Background />
            <RouterSwitch />
            <Popup
                title=""
                className="payment-popup"
                open={isTimeoutCollectorOpen}
                onClose={() => dispatch(setOpenResumeCollectorModal(false))} // Close modal action
                closeBtnDisabled={true}
            >
                <AutoCollectoTimeoutModal
                    onClose={() => dispatch(setOpenResumeCollectorModal(false))} // Close modal action
                />
            </Popup>
            {userInfo?.daily_reward && (
                <Modal
                    className="glowing-modal"
                    title="Your daily rewards"
                    open={openDailyClaimModal}
                    onClose={() => setOpenDailyClaimModal(false)}
                    closeBtnDisabled={true}
                >
                    <DailyClaimModal
                        onClose={() => setOpenDailyClaimModal(false)}
                        title="Come back tomorrow for new tickets"
                        dailyRewards={userInfo?.daily_reward}
                        dailyStreak={dailyStreak}
                    />
                </Modal>
            )}
            <Toaster
                position="top-center"
                reverseOrder={false}
                toastOptions={{
                    style: {
                        fontFamily: 'Urbanist, sans-serif',
                        fontWeight: '500',
                        fontStyle: 'normal',
                        fontSize: '1.8rem',
                    },
                }}
            />
        </BrowserRouter>
    );
}

export default App;

import React, { Suspense, lazy, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { ROUTES } from './routes/index';
import Navbar from './layouts/Navbar/Navbar';
import NotFound from './pages/NotFound/NotFound';
//import * as Sentry from '@sentry/react';
import { AnimatePresence } from 'framer-motion';
//import { trackPageView } from './analytics';

//const SentryRoute = Sentry.withSentryRouting(Route);

const Main = lazy(() => import('./pages/Main/Main'));
const Missions = lazy(() => import('./pages/Missions/Missions'));
const Boost = lazy(() => import('./pages/Boost/Boost'));
const Friends = lazy(() => import('./pages/Friends/Friends'));
const Apps = lazy(() => import('./pages/Apps/Apps'));
const WithdrawHistory = lazy(() =>
    import('./pages/WithdrawHistory/WithdrawHistory')
);

const GuildBoost = lazy(() => import('./pages/GuildBoost/GuildBoost'));
const GuildList = lazy(() => import('./pages/GuildList/GuildList'));
const Guild = lazy(() => import('./pages/Guild/Guild'));

export default function RouterSwitch() {
    const location = useLocation();

    /*useEffect(() => {
        trackPageView(location.pathname + location.search);
    }, [location]);*/

    const navbarPaths = [
        ROUTES.MAIN,
        ROUTES.MISSIONS,
        ROUTES.BOOST,
        ROUTES.FRIENDS,
        ROUTES.APPS,
        ROUTES.WITHDRAW_HISTORY,
    ];

    const showNavbar = navbarPaths.includes(location.pathname);

    return (
        <>
            <Suspense fallback={null}>
                <AnimatePresence mode="wait">
                    <Switch location={location} key={location.pathname}>
                        <Route exact path={ROUTES.MAIN}>
                            <Main />
                        </Route>
                        <Route exact path={ROUTES.MISSIONS}>
                            <Missions />
                        </Route>
                        <Route exact path={ROUTES.BOOST}>
                            <Boost />
                        </Route>
                        <Route exact path={ROUTES.FRIENDS}>
                            <Friends />
                        </Route>
                        <Route exact path={ROUTES.WITHDRAW_HISTORY}>
                            <WithdrawHistory />
                        </Route>
                        <Route exact path={ROUTES.APPS}>
                            <Apps />
                        </Route>
                        <Route exact path={ROUTES.GUILD}>
                            <Suspense fallback={null}>
                                <Guild />
                            </Suspense>
                        </Route>
                        <Route exact path={ROUTES.GUILD_BOOST}>
                            <Suspense fallback={null}>
                                <GuildBoost />
                            </Suspense>
                        </Route>
                        <Route exact path={ROUTES.GUILD_LIST}>
                            <Suspense fallback={null}>
                                <GuildList />
                            </Suspense>
                        </Route>
                        <Route exact path={ROUTES.NOT_FOUND}>
                            <NotFound />
                        </Route>
                    </Switch>
                </AnimatePresence>
            </Suspense>
            {showNavbar && (
                <div
                    className="router-footer"
                    style={{
                        position: 'absolute',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '0 1rem 0',
                        height: '100%',
                        justifyContent: 'flex-end',
                        bottom: '0',
                        marginBottom: 'env(safe-area-inset-bottom)',
                        pointerEvents: 'none',
                    }}
                >
                    <Navbar />
                </div>
            )}
        </>
    );
}

const initNoCache = () => {
    const addCacheBusting = (url) => {
        return url.includes('?')
            ? `${url}&v=${new Date().getTime()}`
            : `${url}?v=${new Date().getTime()}`;
    };

    // Cache busting for all script files
    const scriptTags = document.querySelectorAll('script[src]');
    scriptTags.forEach((script) => {
        script.src = addCacheBusting(script.src);
    });

    // Cache busting for all CSS files
    const linkTags = document.querySelectorAll('link[rel="stylesheet"]');
    linkTags.forEach((link) => {
        link.href = addCacheBusting(link.href);
    });
};

export default initNoCache;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    appPrepared: false,
    userInfo: {},
    counterStopped: false,
    boosts: {},
    friends: [],
    withdraws: [],
    totalFriends: 0,
    linkFriendsNext: null,
    linkWithdrawsNext: null,
    tasks: [],
    achieves: [],
    remindedFriends: [],
    currentFriendsPage: 0,
    totalFriendsPages: 0,
    currentWithdrawsPage: 0,
    referralTokens: 0,
    userPhoto: '',
    tonAmount: 0,
    error: null,
    products: null,
    openResumeCollectorModal: false,
    isPaymentLoading: false,
    games: [],
    dailyRewards: [],
    settings: {},
    totalTons: 0,
    dailyStreak: 0,
};

export const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        setAppPrepared: (state, action) => {
            state.appPrepared = action.payload;
        },
        setUserInfo: (state, action) => {
            state.userInfo = action.payload;
        },
        setBoosts: (state, action) => {
            state.boosts = action.payload;
        },
        setFriends: (state, action) => {
            state.friends = action.payload;
        },
        setWithdraws: (state, action) => {
            state.withdraws = action.payload;
        },
        setTotalFriends: (state, action) => {
            state.totalFriends = action.payload;
        },
        setFriendsLinkNext: (state, action) => {
            state.linkFriendsNext = action.payload;
        },
        setWithdrawsLinkNext: (state, action) => {
            state.linkWithdrawsNext = action.payload;
        },
        setTasks: (state, action) => {
            state.tasks = action.payload;
        },
        setAchieves: (state, action) => {
            state.achieves = action.payload;
        },
        setCurrentFriendsPage: (state, action) => {
            state.currentFriendsPage = action.payload;
        },
        setTotalFriendsPages: (state, action) => {
            state.totalFriendsPages = action.payload;
        },
        setCurrentWithdrawsPage: (state, action) => {
            state.currentWithdrawsPage = action.payload;
        },
        setReferralTokens: (state, action) => {
            state.referralTokens = action.payload;
        },
        setRemindedFriends: (state, action) => {
            state.remindedFriends.push(action.payload);
        },
        setTonAmount: (state, action) => {
            state.tonAmount = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setProducts: (state, action) => {
            state.products = action.payload;
        },
        setOpenResumeCollectorModal: (state, action) => {
            state.openResumeCollectorModal = action.payload;
        },
        setIsPaymentLoading: (state, action) => {
            state.isPaymentLoading = action.payload;
        },
        setGames: (state, action) => {
            state.games = action.payload;
        },
        setDailyRewards: (state, action) => {
            state.dailyRewards = action.payload;
        },
        setSettings: (state, action) => {
            state.settings = action.payload;
        },
        setTotalTons: (state, action) => {
            state.totalTons = action.payload;
        },
        setDailyRewardStreak: (state, action) => {
            state.dailyStreak = action.payload;
        }
    },
});

export const {
    setAppPrepared,
    setUserInfo,
    setBoosts,
    setFriends,
    setWithdraws,
    setTotalFriends,
    setTasks,
    setCurrentFriendsPage,
    setTotalFriendsPages,
    setCurrentWithdrawsPage,
    setReferralTokens,
    setRemindedFriends,
    setAchieves,
    setTonAmount,
    setFriendsLinkNext,
    setWithdrawsLinkNext,
    setError,
    setProducts,
    setOpenResumeCollectorModal,
    setIsPaymentLoading,
    setGames,
    setDailyRewards,
    setSettings,
    setTotalTons,
    setDailyRewardStreak,
} = commonSlice.actions;

export default commonSlice.reducer;

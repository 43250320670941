import React, { useEffect, useState } from 'react';
import './Modal.scss';

const Modal = ({
    className,
    title,
    open,
    onClose,
    closeBtnDisabled,
    footer,
    footerStyle,
    children,
}) => {
    const [modalOpen, setModalOpen] = useState(open);
    const [closing, setClosing] = useState(false);

    useEffect(() => {
        if (open) {
            setModalOpen(open);
        } else {
            setClosing(true);
            setTimeout(() => {
                setModalOpen(false);
                onClose();
                setClosing(false);
            }, 500);
        }
    }, [open]);

    const handleClose = () => {
        setClosing(true);
        setTimeout(() => {
            setModalOpen(false);
            onClose();
            setClosing(false);
        }, 300);
    };

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            handleClose();
        }
    };

    if (!modalOpen) return null;
    return (
        <div
            className={`modal-overlay ${closing ? 'close' : ''}`}
            onClick={closeBtnDisabled ? null : handleOverlayClick}
            style={{ zIndex: '1000 !important' }}
        >
            <div
                className={`modal ${className} ${closing ? 'close' : ''}`}
                style={{
                    zIndex: '1000 !important',
                    willChange: 'unset !important',
                }}
            >
                {!closeBtnDisabled && (
                    <div className="modal-close">
                        <button className="close-button" onClick={handleClose}>
                            <span className="close-icon"></span>
                        </button>
                    </div>
                )}
                {title && (
                    <div className="modal-header">
                        <div className="modal-title">{title}</div>
                    </div>
                )}
                <div className="modal-content">{children}</div>
                {footer && (
                    <div className="modal-footer" style={footerStyle}>
                        {footer}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Modal;

const initTgSettings = () => {
    const tg = window.Telegram.WebApp;

    if (tg) {
        tg.expand();
        tg.enableClosingConfirmation();
        tg.disableVerticalSwipes();
        tg.BackButton.show();
        return true;
    } else {
        return false;
    }
};

export default initTgSettings;

import React, { useEffect } from 'react';
import roadmap_data from '../../helper/roadmap-data';
import StarIcon from "../../assets/components/StarIcon/Circle.svg";
import StarFilledIcon from "../../assets/components/StarIcon/RigelGradient.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import './Roadmap.scss';

const Roadmap = () => {

    useEffect(() => {
        var i = 0;
        function move() {
            if (i === 0) {
                i = 1;
                const elem = document.getElementById('myBar');
                let width = 1;
                const duration = 3000; // 3 seconds
                const interval = 10; // update interval in milliseconds
                const step = 100 / (duration / interval);

                const id = setInterval(frame, interval);
                function frame() {
                    if (width >= 100) {
                        clearInterval(id);
                        i = 0;
                    } else {
                        width += step;
                        elem.style.width = width + '%';
                    }
                }
            }
        }

        move();
    }, []);

    return (
        <div className="roadmap-wrapper">
            <div className="area">
                <ul className="stars">
                    <li>
                        <FontAwesomeIcon icon={faStar} />
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faStar} />
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faStar} />
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faStar} />
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faStar} />
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faStar} />
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faStar} />
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faStar} />
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faStar} />
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faStar} />
                    </li>
                </ul>
            </div>
            <div className="background"></div>
            <div className="roadmap-container">
                <div className="roadmap-title-container">
                    <h1 className="roadmap-title">Roadmap</h1>
                    <span className="roadmap-subtitle">
                        Welcome to Rigel! Stay tuned as we roll out exciting new
                        games, and community-driven events.
                    </span>
                </div>
                {roadmap_data.map((item, index) => (
                    <div
                        key={index}
                        className={`roadmap-item-container ${
                            item.active ? 'roadmap-item-container-active' : ''
                        }`}
                    >
                        <h1 className="roadmap-item-title">{item.title}</h1>
                        {item.data.map((data, index) => (
                            <div key={index} className="roadmap-item">
                                <span className="status">
                                    <img
                                        src={
                                            data.status
                                                ? StarFilledIcon
                                                : StarIcon
                                        }
                                        className={`status-icon ${
                                            data.status
                                                ? 'status-complete'
                                                : 'status-incomplete'
                                        }`}
                                        alt="status"
                                    />
                                </span>
                                <span
                                    className={`task ${
                                        data.status
                                            ? 'task-complete'
                                            : 'task-incomplete'
                                    }`}
                                >
                                    {data.task}
                                </span>
                            </div>
                        ))}
                    </div>
                ))}
                <div id="myProgress">
                    <div id="myBar"></div>
                </div>
            </div>
        </div>
    );
};

export default Roadmap;

import React from 'react';
import Button from '../../components/Button/Button';
import './NotFound.scss';

const NotFound = () => (
    <div className="not-found-container">
        <Button to="/" type="test">Back</Button>
        <h1>404</h1>
        <h3>It seems like you're trying to access</h3>
        <h3>a page that doesn't exist</h3>
    </div>
);

export default NotFound;

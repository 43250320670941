import { Component } from 'react';

class ErrorBoundary extends Component {
    state = {
        error: null,
    };

    static getDerivedStateFromError(error) {
        return { error };
    }

    componentDidCatch(error, info) {
        console.error('Error caught in ErrorBoundary:', error, info);
    }

    render() {
        const { error } = this.state;
        const { FallbackComponent, children } = this.props;

        if (error && FallbackComponent) {
            return <FallbackComponent error={error} />;
        }

        return children;
    }
}

export default ErrorBoundary;


const ROUTES = {
    MAIN: '/',
    MISSIONS: '/missions',
    BOOST: '/boost',
    FRIENDS: '/friends',
    WITHDRAW_HISTORY: '/friends/history',
    APPS: '/apps',
    GUILD: '/guild',
    GUILD_BOOST: '/guild-boost',
    GUILD_LIST: '/guild-list',
    NOT_FOUND: '*',
};

const IconDisabledOnROUTES = {
    WITHDRAW_HISTORY: ROUTES.WITHDRAW_HISTORY,
    ANOTHER: '/another',
};

  

export { ROUTES, IconDisabledOnROUTES };